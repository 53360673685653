import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contact</h2>
          </div>
          <form
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
            name="contact"
          >
            <div hidden>
              <input name="oid" value="00D5e000001AEui" />
              <input name="retURL" value="http://brynervocalstudio.com" />
            </div>
            <div>
              <label>
                First Name
                <input
                  id="fist_name"
                  maxLength="40"
                  name="first_name"
                  size="20"
                  type="text"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Last Name
                <input
                  id="last_name"
                  maxLength="80"
                  name="last_name"
                  size="20"
                  type="text"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Phone
                <input
                  id="phone"
                  maxLength="40"
                  name="phone"
                  size="20"
                  type="tel"
                />
              </label>
            </div>
            <div>
              <label>
                Email
                <input
                  id="email"
                  maxLength="80"
                  name="email"
                  size="20"
                  type="email"
                  required
                />
              </label>
            </div>
            <div>
              <label>
                Message
                <textarea name="description"></textarea>
              </label>
            </div>
            <div hidden>
              <div>
                <label>
                  Company
                  <input
                    id="company"
                    maxLength="40"
                    name="company"
                    size="20"
                    type="text"
                    value="BrynerVocalStudio.com"
                  />
                </label>
              </div>
              <div>
                <label>
                  Tech Support:
                  <input
                    id="00N5e00000AzEvr"
                    maxLength="80"
                    name="00N5e00000AzEvr"
                    size="20"
                    type="text"
                  />
                </label>
              </div>
            </div>
            <div>
              <button type="submit" name="submit">
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
